import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { NbMenuItem, NbMenuService } from '@nebular/theme';

import { AuthService } from '../auth/services/auth.service';
import { MENU_ITEMS } from './pages-menu';

@Component({
  selector: 'ngx-pages',
  styleUrls: ['pages.component.scss'],
  template: `
    <ngx-one-column-layout>
      <nb-menu class="main-sidebar" [items]="menu" tag="mainMenu"></nb-menu>
    </ngx-one-column-layout>
  `,
})
export class PagesComponent implements OnInit, AfterViewInit {
  public menu = MENU_ITEMS;
  constructor(private authService: AuthService, private nbMenuService: NbMenuService, private titleService: Title) {}

  ngOnInit() {
    this.nbMenuService.onItemClick().subscribe((menuBag) => {
      if (menuBag.tag === 'mainMenu') {
        this.titleService.setTitle(menuBag.item.title);
        if (menuBag.item.title === 'Logout') {
          this.logout();
        }
      }
    });
    this.filterMenu(this.menu);
  }

  ngAfterViewInit() {
    this.clearNbMenuTooltips();
  }

  private clearNbMenuTooltips() {
    const elements = document.querySelectorAll('ul.menu-items > li > a[title]');
    for (const element of elements as any) {
      element.title = '';
    }
  }

  filterMenu(menu: NbMenuItem[]) {
    for (const item of menu) {
      item.hidden =
        (item?.data?.permissions && !this.authService.checkPermissions(item.data?.permissions ?? [])) ||
        (item?.data?.isRootRoleRequired && !this.authService.hasRootPermission);
      if (item.children) {
        this.filterMenu(item.children);
      }
    }
  }

  logout() {
    this.authService.logout().subscribe(() => {
      window.location.href = '/auth/login';
    });
  }
}
