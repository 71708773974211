import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { PermissionsEnum } from '../auth/models/permissions.model';
import { canActivateByRole } from '../auth/services/auth-guard.service';
import { userInfoResolver } from '../auth/services/user-info.resolver';
import { ForbiddenComponent } from '../shared/components/forbidden/forbidden.component';
import { LoadingpageComponent } from '../shared/components/loadingpage/loadingpage.component';
import { NotFoundComponent } from '../shared/components/not-found/not-found.component';
import { PagesComponent } from './pages.component';

const routes: Routes = [
  {
    path: '',
    component: PagesComponent,
    children: [
      {
        path: 'account',
        loadChildren: () => import('./account/account.module').then((m) => m.AccountModule),
        canActivate: [canActivateByRole],
        canActivateChild: [canActivateByRole],
        data: {
          permissions: [PermissionsEnum.CHANGE_PASSWORD],
        },
        resolve: {
          accountInfo: userInfoResolver,
        },
      },
      {
        path: 'smart-flow',
        loadChildren: () => import('./smart-flow/smart-flow.module').then((m) => m.SmartFlowModule),
        canActivate: [canActivateByRole],
        canActivateChild: [canActivateByRole],
        resolve: {
          accountInfo: userInfoResolver,
        },
      },
      {
        path: 'crm',
        loadChildren: () => import('./crm/crm.module').then((m) => m.CrmModule),
        canActivate: [canActivateByRole],
        canActivateChild: [canActivateByRole],
        resolve: {
          accountInfo: userInfoResolver,
        },
      },
      {
        path: 'kyc',
        loadChildren: () => import('./kyc/kyc.module').then((m) => m.KycModule),
        canActivate: [canActivateByRole],
        canActivateChild: [canActivateByRole],
        resolve: {
          accountInfo: userInfoResolver,
        },
      },
      {
        path: 'rpa',
        loadChildren: () => import('./rpa/rpa-tasks.module').then((m) => m.RpaTasksModule),
        canActivate: [canActivateByRole],
        canActivateChild: [canActivateByRole],
        resolve: {
          accountInfo: userInfoResolver,
        },
      },
      {
        path: 'reports',
        loadChildren: () => import('./reports/reports.module').then((m) => m.ReportsModule),
        canActivate: [canActivateByRole],
        canActivateChild: [canActivateByRole],
        resolve: {
          accountInfo: userInfoResolver,
        },
      },
      {
        path: 'configuration',
        loadChildren: () => import('./configuration/configuration.module').then((m) => m.ConfigurationModule),
      },
      {
        path: '',
        redirectTo: 'smart-flow/dashboard',
        pathMatch: 'full',
      },
      {
        path: 'loading',
        component: LoadingpageComponent,
      },
      {
        path: '403',
        component: ForbiddenComponent,
      },
      {
        path: '**',
        component: NotFoundComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PagesRoutingModule {}
