import { Component } from '@angular/core';

import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'bia-cancel-your-flow',
  template: `
    <nb-card>
      <nb-card-header
        >Are you sure you want to cancel your flow? <br />By clicking “yes” all unsaved changes will be discarded.
      </nb-card-header>
      <nb-card-footer class="d-flex">
        <button style="width: 150px;" nbButton outline shape="round" status="primary" (click)="close('yes')">
          Yes
        </button>
        <button style="width: 150px;" nbButton shape="round" status="primary" (click)="close('no')">
          Don't Cancel
        </button>
      </nb-card-footer>
    </nb-card>
  `,
})
export class CancelYourFlowDialogPromptComponent {
  constructor(protected dialogRef: NbDialogRef<CancelYourFlowDialogPromptComponent>) {}

  close(text) {
    this.dialogRef.close(text);
  }
}
