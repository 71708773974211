import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { FlowActionTabModel } from '../models/flow-actions-tab.model';
import { FlowDescriptionTabModel } from '../models/flow-description-tab.model';
import { FlowTriggerModel } from '../models/flow-model.model';
import { TempFlowEventTriggerModel } from '../models/new-flow-event-trigger.model';
import { NewReceiveReportModel } from '../models/new-receive-report.model';

/**
 * DEPRECATE THIS SERVICE IN THE FUTURE
 * Use the service `flow-update` instead of this one, as that supports update of flow based on ID,
 * hence multiple flow's updates can be tracked.
 */

@Injectable()
export class FlowTabTempService {
  private subjectFlowTriggerTemp$ = new BehaviorSubject<Partial<FlowTriggerModel>>(null);
  private subjectFlowDescTemp = new Subject<FlowDescriptionTabModel>();
  private subjectFlowActionTemp = new Subject<FlowActionTabModel[]>();
  private subjectFlowReceiversTemp = new Subject<NewReceiveReportModel>();
  private subjectFlowTestTemp = new Subject<any>();
  private subjectFlowEventTrigsTemp = new Subject<TempFlowEventTriggerModel>();
  private flowID: number = null;
  private version: number = null;
  public flowItem: FlowDescriptionTabModel = new FlowDescriptionTabModel();
  public flowEventTriggers: TempFlowEventTriggerModel = {} as TempFlowEventTriggerModel;
  public flowAction: FlowActionTabModel[] = [];
  public flowName: string = null;

  getFlowDescTemp(): Observable<FlowDescriptionTabModel> {
    return this.subjectFlowDescTemp.asObservable();
  }

  saveFlowTest(temp: any) {
    this.subjectFlowTestTemp.next(temp);
  }

  getFlowTest(): Observable<any> {
    return this.subjectFlowTestTemp.asObservable();
  }

  saveFlowTriggerTemp(temp: Partial<FlowTriggerModel>) {
    this.subjectFlowTriggerTemp$.next(temp);
  }

  getFlowTriggerTemp(): Partial<FlowTriggerModel> {
    return this.subjectFlowTriggerTemp$.getValue();
  }

  getFlowTriggerTempObservable(): Observable<Partial<FlowTriggerModel>> {
    return this.subjectFlowTriggerTemp$.asObservable();
  }

  saveFlowEventTrigsTemp(temp: TempFlowEventTriggerModel) {
    this.subjectFlowEventTrigsTemp.next(temp);
  }

  getFlowEventTrigsTemp(): Observable<TempFlowEventTriggerModel> {
    return this.subjectFlowEventTrigsTemp.asObservable();
  }

  saveFlowEventTriggers(temp: TempFlowEventTriggerModel) {
    this.flowEventTriggers = temp;
  }

  saveFlowActionTemp(temp: FlowActionTabModel[]) {
    this.subjectFlowActionTemp.next(temp);
  }

  getFlowActionTemp(): Observable<FlowActionTabModel[]> {
    return this.subjectFlowActionTemp.asObservable();
  }

  saveFlowAction(temp: FlowActionTabModel[]) {
    this.flowAction = temp;
  }

  getFlowAction() {
    return this.flowAction;
  }

  getFlowReceiversTemp(): Observable<NewReceiveReportModel> {
    return this.subjectFlowReceiversTemp.asObservable();
  }

  saveFlowID(temp: number) {
    this.flowID = temp;
  }

  getFlowID() {
    return this.flowID;
  }

  saveVersion(temp: number) {
    this.version = temp;
  }

  getVersion() {
    if (this.version) {
      return this.version;
    } else {
      return 1;
    }
  }

  saveFlowName(temp: string) {
    this.flowName = temp;
  }

  getFlowName() {
    return this.flowName;
  }
}
